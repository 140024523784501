<template>
	<div>
		<TourHeader
			class="tour-header"
			v-if="!loading && !error"

			:tripID="tripID"
			:images="tour.images"
			:tourName="tour.name"
			:tourStartLocation="tour.itinerary.data.start_location.name"
			:tourEndLocation="tour.itinerary.data.end_location && tour.itinerary.data.end_location.name != tour.itinerary.data.start_location.name ? tour.itinerary.data.end_location.name : false"
			:operator="tour.booking_companies.join(', ')"
		/>
		<div class="outer-wrapper">
			<Loader :loading="loading" :error="error" @refresh="init"/>

			<div v-if="!loading && !error && saved">
				<div class="inner-wrapper" v-if="trip.tripItems[tripItemID].alerts.all.length > 0">
					<div class="notification-action" :class="trip.tripItems[tripItemID].alerts.all[0].alert_code">
						<div class="title">{{ trip.tripItems[tripItemID].alerts.all[0].main_message }}</div>
						<div class="text" v-html="trip.tripItems[tripItemID].alerts.all[0].description"></div>
						<ResultIcon class="icon" type="neutral" :message="trip.tripItems[tripItemID].alerts.all[0].secondary_message" />
						
						<div v-if="trip.tripItems[tripItemID].alerts.all[0].alert_type == 'traveller_validation'">
							<button class="button main" @click="alertAction('traveller_validation')">Edit Travellers</button>
						</div>
						<div v-else-if="trip.tripItems[tripItemID].alerts.all[0].alert_type == 'expiry'">
							<div class="other">Select New Departure</div>
							<button class="button" @click="alertAction('dates')">Search</button>
						</div>
						<div v-else-if="trip.tripItems[tripItemID].alerts.all[0].alert_type == 'capacity'">
							<button class="button main" @click="alertAction('traveller_validation')">Edit Travellers</button>
							<div class="or">or</div> 
							<div class="other">Select New Departure</div>
							<button class="button" @click="alertAction('dates')">Search</button>
						</div>
						<div v-else>
							<!-- <button class="btn" @click="alertAction()">Continue</button> -->
						</div>
						
						<div v-if="status.code == 'not_booked'">
							<div class="or">or</div> 
							<div class="remove-item" @click="removeFromTrip()">Remove Tour</div>
						</div>
					</div>
				</div>
			</div>

			<div class="inner-wrapper" v-if="!loading && !error">
				
				<div v-if="saved && status.code == 'booked' && trip.tripItems[this.tripItemID]">
					<div class="res-no">
						External Booking ID:
						<span class="no">{{ trip.tripItems[this.tripItemID].bookingStatus.gapiBookingID }}</span>
						Your internal booking reference number is:
						<span class="no">{{ trip.tripItems[this.tripItemID].bookingStatus.bookingID }}</span>
					</div>
					
					<div class="inner-wrapper" v-if="status.code == 'booked'">
						<div class="notification-action green">
							<div class="title">Booking is successful</div>
							<div class="text">Your tour booking is successful. Have a nice trip!</div>
							<ResultIcon class="icon" type="success" :message="'Booked!'" />
						</div>
					</div>
				</div>

				<div class="passengers" v-if="saved && trip.tripItems[this.tripItemID]">
					<div class="heading">
						Travellers <img class="edit-section" v-if="status.code == 'not_booked'" @click="manageTravellers()" src="@/assets/travellers/edit-travellers.png" alt="Edit Passengers Icon" />
					</div>
					<div class="pax-outer" v-for="(pax, index) in trip.tripItems[this.tripItemID].travellers" :key="index"> <!-- each of these becomes a circular pax icon thing. For now, put generic icon instead of profile pic -->
						<TravellerIcon
							:name="pax.data.firstName"
							:lastName="pax.data.lastName"
							:type="pax.type"
							@click="editPax(pax.traveller_id)"
						/>
					</div>
					<div class="pax-outer" v-if="status.code == 'not_booked'">
						<AddTravellerIcon @click="manageTravellers()"/>
					</div>
				</div>
				<div v-if="fullDetails">
					<div class="summary-section">

						<div class="selling-point">
							<div class="point">Book with flexibility</div>
							<div class="point">
								<span class="length" v-if="tour.itinerary.data.duration > 1">{{ tour.itinerary.data.duration }} day tour</span>
								<span class="length" v-else>Day trip</span>
							</div>
							
						</div>	

						<div class="price" v-if="fromPrice">
							<span class="starting">Starting from</span>
							<div class="amount">
								{{ currencySymbol(fromPrice.currency) }}{{ fromPrice.amount.substr(0, fromPrice.amount.indexOf('.')) }}<span class="small">{{ fromPrice.amount.substr(fromPrice.amount.indexOf('.'), fromPrice.amount.length) }}</span>
							</div>
						</div>

						<div class="clear"></div>
					</div>
					<div class="about">

						<div class="heading-area">
							<img class="icon" src="@/assets/tours/about-icon.png" width="19px" alt="About Tour Icon">
							<h2 class="text">About Tour</h2>
						</div>

						<div class="days">
							<img class="icon" src="@/assets/tours/day-icon-blue.png" width="19px" alt="Tour Length Icon">

							<span class="length" v-if="tour.itinerary.data.duration > 1">{{ tour.itinerary.data.duration }} days</span>
							<span class="length" v-else>Day trip</span>
						</div>
						
						<!-- Display more link if description is longer than x characters -->
						<div class="content" v-if="tour.description.length < 800">
							{{ tour.description }}
						</div>
						<div class="content" v-else-if="!showFullAbout && tour.description.length > 800">
							{{ tour.description.substring(0, 800) }}<span class="more" @click="showFullAbout = !showFullAbout">... more</span>
						</div>
						<div class="content" v-else-if="showFullAbout && tour.description.length > 800">
							{{ tour.description}}<div class="more" @click="showFullAbout = !showFullAbout">... less</div>
						</div>

					</div>
				</div>
			</div>

			<div class="whats-included" v-if="fullDetails && (!loading && !error) && (Object.keys(accommodationSummary).length > 1 || Object.keys(transportSummary).length > 1 || Object.keys(activitySummary).length > 1 || tour.itinerary.data.meals_included)">
				
				<div class="inner-wrapper">
					<div class="heading-area">
						<img class="icon" src="@/assets/tours/itinerary-icon.png" width="24px">
						<h2 class="text">What's Included</h2>
					</div>
				</div>

				<div class="inner-wrapper section" v-if="Object.keys(accommodationSummary).length > 1">
					<div class="heading" :class="showAccommodation ? 'show' : 'hide'" @click="showAccommodation = !showAccommodation">
						<img class="icon" src="@/assets/tours/whats-included/accommodation-icon.png" width="18px">
						<h3>Accommodation</h3>
						<img class="arrow" v-if="showAccommodation" src="@/assets/arrows/green-arrow.png" width="12px"/>
						<img class="arrow" v-else src="@/assets/arrows/plus-icon.png" width="12px"/>
					</div>
					<div class="content" v-if="showAccommodation">
						<div v-for="(data, type) in accommodationSummary" :key="type">
							{{ data.length }} nights {{ type }}
						</div>
					</div>
				</div>

				<div class="inner-wrapper section meals">
					<div class="heading" :class="showMeals ? 'show' : 'hide'" @click="showMeals = !showMeals">
						<img class="icon" src="@/assets/tours/whats-included/meals-icon.png" width="18px">
						<h3>Meals</h3>
						<img class="arrow" v-if="showMeals" src="@/assets/arrows/green-arrow.png" width="12px"/>
						<img class="arrow" v-else src="@/assets/arrows/plus-icon.png" width="12px"/>
					</div>
					<div class="content" v-if="showMeals">
						<div class="item" v-for="(value, key) in tour.itinerary.data.meals_included" :key="key" :class="value == 0 ? 'none' : ''">
							<img class="icon" :src="mealIcon(key)" height="20px" alt="Meal Icon">
							<div class="amount">{{ value }}x</div>
							<div class="type">{{ key }}</div>
						</div>
					</div>
				</div>
				
				<div class="inner-wrapper section" v-if="Object.keys(transportSummary).length > 1">
					<div class="heading" :class="showTransport ? 'show' : 'hide'" @click="showTransport = !showTransport">
						<img class="icon" src="@/assets/tours/whats-included/transport-icon.png" width="18px">
						<h3>Transport</h3>
						<img class="arrow" v-if="showTransport" src="@/assets/arrows/green-arrow.png" width="12px"/>
						<img class="arrow" v-else src="@/assets/arrows/plus-icon.png" width="12px"/>
					</div>
					<div class="content" v-if="showTransport">
						<div v-for="(data, type) in transportSummary" :key="type">
							{{ data.length }} x	{{ type }}
						</div>
					</div>
				</div>

				<div class="inner-wrapper section" v-if="Object.keys(transportSummary).length > 1">
					<div class="heading" :class="showActivity ? 'show' : 'hide'" @click="showActivity = !showActivity">
						<img class="icon" src="@/assets/tours/whats-included/activity-icon.png" width="18px">
						<h3>Activities</h3>
						<img class="arrow" v-if="showActivity" src="@/assets/arrows/green-arrow.png" width="12px"/>
						<img class="arrow" v-else src="@/assets/arrows/plus-icon.png" width="12px"/>
					</div>
					<div class="content" v-if="showActivity">
						<ul>
							<li v-for="(data, type) in activitySummary" :key="type">
								{{ type }}
							</li>
						</ul>
					</div>
				</div>

			</div>

			<div class="itinerary" v-if="fullDetails && !loading && !error">
				
				<div class="inner-wrapper">
					<div class="heading-area">
						<img class="icon" src="@/assets/tours/itinerary-icon.png" width="24px">
						<h2 class="text">Itinerary</h2>
					</div>
				</div>
				
				<div class="sections">
					<div class="section" v-if="tour.itinerary.data.details.find(obj => { return obj.type.code == 'INTRODUCTION'}).body != tour.description">
						<div class="inner-wrapper">
							<div class="title" @click="showIntro = !showIntro">
								<div class="icon">
									<img v-if="showIntro" src="@/assets/tours/introduction-icon.png" width="16px">
									<img v-else src="@/assets/tours/introduction-off-icon.png" width="16px">
								</div>
								<h4 class="text">Introduction</h4>
								<img class="arrow" src="@/assets/trip/arrow-up.png" width="24px" :style="[showIntro ? { transform: 'rotate(0deg)'} : {}]"/>
							</div>

							<div class="content" :class="showIntro ? 'show' : 'hide'">
								<div class="plaintext">
									<p class="summary">{{ tour.itinerary.data.details.find(obj => { return obj.type.code == 'INTRODUCTION'}).body }}</p>
								</div>
							</div>
						</div>
					</div>
					
					<div class="section" v-for="day in itineraryDays" :key="day.id">
						<div class="inner-wrapper" v-if="itineraryDays.length > 1">
							<div class="title" @click="day.show = !day.show" :class="day.show ? 'show' : 'hide'">
								<div class="icon">
									<img v-if="day.day == 1 && !day.show" src="@/assets/tours/start-icon.png" width="12px">
									<img v-else-if="day.day == itineraryDays.length && !day.show" src="@/assets/tours/end-icon.png" width="16px">
									<img v-else-if="day.day == itineraryDays.length && day.show" src="@/assets/tours/end-icon-green.png" width="16px">
									<span v-else class="dot"></span>
								</div>
								<h4 class="text">Day {{ day.day }}</h4>
								<span class="locations">
									<span class="start">{{ day.start_location.name }}</span>
									<span class="end" v-if="day.end_location && day.end_location.name != day.start_location.name"> - {{ day.end_location.name }}</span>
								</span>
								<img class="arrow" src="@/assets/trip/arrow-up.png" width="24px" />
							</div>
						</div>

						<div class="content" :class="itineraryDays.length <= 1 || day.show ? 'show' : 'hide'">
							<div class="included" v-if="day.meals && day.meals.length > 0">
								<div class="inner-wrapper">
									<span class="item" v-for="meal in day.meals" :key="meal.type">
										<img class="icon" :src="mealIcon(meal.type.toLowerCase())" height="20px" alt="Meal Icon">
										{{meal.type.toLowerCase()}}
									</span>
								</div>
							</div>

							<div class="inner-wrapper">
								<div class="components">
									<div v-for="(component, index) in day.components" :key="index">
										<div class="transport" v-if="component.type == 'TRANSPORT'">
											<img class="icon" :src="getTransportIcon(component.transport_dossier.name)" width="20px"/> 
											<div class="text">
												<div class="locations">
													<span class="start">{{ component.start_location.name }}</span>
													<span class="end" v-if="component.end_location && component.end_location.name != component.start_location.name"> - {{ component.end_location.name }}</span>
												</div>
												<div class="details">
													<span class="title">Transport: </span>
													<span class="type" v-if="component.transport_dossier">{{ component.transport_dossier.name }}</span>
													<span class="length" v-if="component.duration && component.duration.min_hr">
														<img class="icon" src="@/assets/tours/transport/length-icon.png" width="10px"/> 
														<span class="min">{{ component.duration.min_hr }}h</span>
														<span class="max" v-if="component.duration.max_hr"> to {{ component.duration.max_hr }}</span>
													</span>
													<span class="distance" v-if="component.distance">
														<img class="icon" src="@/assets/tours/transport/distance-icon.png" height="9px"/> 
														{{ component.distance.name }} km
													</span>
												</div>
											</div>
											<div class="info" v-if="component.summary || component.description || component.instructions">
												<span class="summary" v-if="component.summary">{{ component.summary }} </span>
												<span class="description" v-if="component.description">{{ component.description }} </span>
												<span class="instructions" v-if="component.instructions">{{ component.instructions }} </span>
											</div>
										</div>
										<div class="activity" :class="component.type.toLowerCase()" v-else-if="component.type == 'ACTIVITY' || component.type == 'FREE_TIME'">
											<img v-if="component.type == 'ACTIVITY'" class="icon" src="@/assets/tours/activity-icon.png" width="24px" /> <!-- Make this use the component type to determine the icon image -->
											<img v-else class="icon" src="@/assets/tours/free-time-icon.png" width="24px"/> <!-- Make this use the component type to determine the icon image -->
											<div class="info">
												<span v-if="component.type == 'ACTIVITY'" class="name">Activity: </span>
												<span v-else class="name">Free time: </span>
												<span class="dossier" v-if="component.activity_dossier">{{ component.activity_dossier.name }} <br></span>
												<span class="summary" v-if="component.summary">{{ component.summary }} </span>
												<span class="description" v-if="component.description">{{ component.description }} </span>
												<span class="instructions" v-if="component.instructions">{{ component.instructions }} </span>
											</div>
										</div>
										<div class="activity accommodation" v-else-if="component.type == 'ACCOMMODATION'">
											<img class="icon" src="@/assets/tours/accommodation-icon.png" width="32px" 	/> <!-- Make this use the component type to determine the icon image -->
											<div class="info">
												<div class="name">ACCOMMODATION </div>
												<span class="dossier" v-if="component.accommodation_dossier">{{ component.accommodation_dossier.name }} </span>
												<span class="summary" v-if="component.summary && component.summary != component.accommodation_dossier.name">{{ component.summary }} </span>
												<span class="description" v-if="component.description">{{ component.description }} </span>
												<span class="instructions" v-if="component.instructions">{{ component.instructions }} </span>
											</div>
										</div>
										<div class="other" v-else> <!-- Unknown type.. careful. -->
											<strong> NEW TYPE FOUND - DEVELOPMENT INFO ONLY. </strong> {{ component.type }}
										</div>
									</div>
								</div>
								<div class="plaintext">
									<p class="summary" v-if="day.summary">{{ day.summary }}</p>
									<p class="description" v-if="day.description">{{ day.description }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="inner-wrapper" v-if="fullDetails && (!loading && !error)">
				<div class="map">
					<div class="heading-area">
						<img class="icon" src="@/assets/tours/map-icon.png" width="20px">
						<h2 class="text">Map</h2>
					</div>
					<div class="map-container">
						<img class="image" :src="tour.images.find(obj => { return obj.type == 'MAP'}).image_href" />
					</div>
				</div>

				<div class="additional" v-if="tour.details">
					<div class="heading-area">
						<img class="icon" src="@/assets/tours/info-icon.png" width="20px">
						<h2 class="text">Additional Info</h2>
					</div>
					<div v-for="(item, index) in tourDetails" :key="index">
						<div v-if="index < 3 || showFullAdditionalInfo">
							<!-- Don't display if the HTML contains email or HTML -->
							<div class="item" v-if="!containsHTML(item.body) && !containsEmail(item.body)">
								<span class="detail_type">{{ item.detail_type.label }} - </span>
								<!-- Display more link if description is longer than x characters -->
								<span class="content" v-if="item.body.length < 150">
									<span v-html="item.body"></span>
								</span>
								<span class="content" v-else-if="!item.show && item.body.length > 150">
									<span v-html="item.body.substring(0, 150)"></span>
									<span class="more" @click="item.show = !item.show">...more</span>
								</span>
								<span class="content" v-else-if="item.show && item.body.length > 150">
									<span v-html="item.body"></span>
									<div class="more" @click="item.show = !item.show">... less</div>
								</span>
							</div>
						</div>
					</div>
					<div class="show-more" @click="showFullAdditionalInfo = !showFullAdditionalInfo">
						<span v-if="showFullAdditionalInfo">View less</span>
						<span v-if="!showFullAdditionalInfo">View more</span>
						<img class="arrow" src="@/assets/trip/arrow-up.png" width="24px" :style="[!showFullAdditionalInfo ? { transform: 'rotate(180deg)'} : {}]"/>
					</div>
				</div>
			</div>

			<div class="inner-wrapper" v-if="!fullDetails && (!loading && !error && trip.tripItems[this.tripItemID])"> 
				<TourCard
					:name="trip.tripItems[this.tripItemID].name"
					:images="trip.tripItems[this.tripItemID].data.images"
					:startLocation="trip.tripItems[this.tripItemID].data.startLocation.name"
					:endLocation="trip.tripItems[this.tripItemID].data.endLocation && trip.tripItems[this.tripItemID].data.endLocation.name != trip.tripItems[this.tripItemID].data.startLocation.name ? trip.tripItems[this.tripItemID].data.endLocation.name : false"
					:startDate="trip.tripItems[this.tripItemID].data.startDate"
					:finishDate="trip.tripItems[this.tripItemID].data.finishDate ? trip.tripItems[this.tripItemID].data.finishDate : false"
					:length="trip.tripItems[this.tripItemID].data.length"
					:mealsIncluded="trip.tripItems[this.tripItemID].data.mealsIncluded"
					
					:linkFullDetails="{ name: 'Saved Tour Details Full', params: { trip_id: trip.tripID, trip_item_id: trip.tripItems[this.tripItemID].id } }"
					class="item"
				/>
			</div>
				<!-- <pre>{{ tour.details }} </pre> -->
				<!-- Testing tour card
				<ToursOption
					class="tour-card"

					:id="tour.id"
					:name="tour.name"
					:image="tour.images ? tour.images.find(obj => { return obj.type == 'BANNER' }).image_href : false"
					:startLocation="tour.itinerary.data.start_location.name"
					:endLocation="tour.itinerary.data.end_location.name"
					:length="tour.itinerary.data.duration"
					:description="tour.description"
					:fromPrice="tour.advertised_price ? tour.advertised_price.find(obj => { return obj.currency == 'GBP' }) : false"
				/> -->

			<div class="inner-wrapper" v-if="!loading && !error">
				<div class="dates" ref="dates">
					<div class="heading">
						Dates
					</div>
					<div class="selector" v-if="!selectedDepartureKey && !dateLoading">
						<div v-if="dateOptions.length > 0">
							<div class="select-date">Please select a date</div>
							<v-date-picker 
								class="datepicker scroll"
								v-model="selectedDate"
								mode="date" 
								color="customblue"
								:attributes="calendarSettings.attributes"
								:available-dates="calendarSettings.availableDates"
								@dayclick="selectDate"
							>
							</v-date-picker>
						</div>
						<div v-else>
							<div class="select-date">No dates available!</div>
						</div>
					</div>
					<div class="selected" v-else-if="!dateLoading">
						<div class="date">
							{{ formatDate(new Date(selectedDate)) }}
							<span v-if="selectedEndDate"> - {{ formatDate(new Date(selectedEndDate)) }}</span>
							<img class="edit-section" v-if="!saved || status.code == 'not_booked'" @click="selectedDepartureKey = false; selectedRoomCode = false;" src="@/assets/tours/edit-dates-icon.png" alt="Edit Dates Icon"/>
						</div>

						<!-- If there's no currently selected room code - show all room options -->
						<div class="heading">
							Ticket
						</div>
						<div class="" v-if="saved && trip.tripItems[tripItemID].bookingStatus.code == 'booked'">
							<div class="booked-ticket">
								<strong class="room_name">{{ trip.tripItems[tripItemID].data.room.name }} ticket</strong>
								<div class="info">
									<!-- we have no room level pricing information for booked tours -->
									<!-- <div class="description">
										<span class="availability">
											Availability: {{ room.availability.total }} spaces remaining.
										</span>
										<span class="pricing">
											<div v-for="band in room.price_bands" :key="band.code" class="type">
												<img v-if="band.code == 'ADULT' || band.code == 'CHILD' || band.code == 'INFANT'" :src="getImage('travellers/' + band.code.toLowerCase() + '-icon')" class="pax-icon" :alt="type + 'Icon'" />
												<img v-else src="@/assets/travellers/adult-icon.png" class="pax-icon" :alt="type + 'Icon'" />
												{{ band.name }} (ages {{ band.min_age }} - {{ band.max_age }}) 
												<div class="price">
													<span class="standard" :class="{ 'cross': band.prices.find(ele => ele.currency == 'GBP').promotions.length > 0 }">£{{ band.prices.find(ele => ele.currency == "GBP").amount }}</span>
													<span class="promo" v-if="band.prices.find(ele => ele.currency == 'GBP').promotions.length > 0">£{{ band.prices.find(ele => ele.currency == "GBP").promotions.reduce(function(prev, curr) { return prev.amount < curr.amount ? prev : curr }).amount }}</span>
												</div>
											</div>
										</span>
									</div> -->
								</div>
							</div>
						</div>
						<div v-else>
							<div class="fare-card" v-for="room in roomOptions" :key="room.code" v-show="(room.code == selectedRoomCode) || !selectedRoomCode">
								<div class="ticket">
									<strong class="room_name">{{ room.name }}</strong>
									<div v-if="room.availability.status == 'full'">
										No spaces remaining on this trip. Try change the date.
									</div>
									<div class="info" v-else-if="room.availability.status = 'AVAILABLE'">
										<div class="description">
											<span class="availability">
												Availability: {{ room.availability.total }} spaces remaining.
											</span>
											<span class="pricing">
												<div v-for="band in room.price_bands" :key="band.code" class="type">
													<img v-if="band.code == 'ADULT' || band.code == 'CHILD' || band.code == 'INFANT'" :src="getImage('travellers/' + band.code.toLowerCase() + '-icon')" class="pax-icon" :alt="type + 'Icon'" />
													<img v-else src="@/assets/travellers/adult-icon.png" class="pax-icon" :alt="type + 'Icon'" />
													{{ band.name }} (ages {{ band.min_age }} - {{ band.max_age }}) 
													<!-- Non-promo price. Add a class to strike through it if there's a promo price -->
													<div class="price">
														<span class="standard" :class="{ 'cross': band.prices.find(ele => ele.currency == 'GBP').promotions.length > 0 }">£{{ band.prices.find(ele => ele.currency == "GBP").amount }}</span>
														<!-- Promo price only displayed if there is one -->
														<span class="promo" v-if="band.prices.find(ele => ele.currency == 'GBP').promotions.length > 0">£{{ band.prices.find(ele => ele.currency == "GBP").promotions.reduce(function(prev, curr) { return prev.amount < curr.amount ? prev : curr }).amount }}</span>
													</div>
												</div>
											</span>
											
										</div>
									</div>

									<div class="button-holder">
										<button class="button selected" v-if="room.code == selectedRoomCode">
											Selected
										</button>
										<button class="button select" v-else @click="selectRoom(room)">
											Select
										</button>
										<button class="button change" v-if="room.code == selectedRoomCode" @click="selectedRoomCode = false">
											Change Room
										</button>
									</div>

								</div>
							</div>
						</div>
					</div>

					<Loader :loading="dateLoading"/>
				</div>
			</div>

			<TripItemsSummary 
				v-if="trip.tripItems[tripItemID] && !loading && !dateLoading && saved && ((selectedRoomCode && selectedDepartureKey) || status.code == 'booked')"
				:tripID="trip.tripID"
				:groupedTripItems="{ 'Summary': {
						items: [ trip.tripItems[tripItemID] ],
						totalPrice: trip.tripItems[tripItemID].price,
						heading: 'Summary'
					}
				}"
				
				:showBookButton="true"
				@book="goToPayment()"
			/>

			<div v-if="!dateLoading && !saved && selectedRoomCode && selectedDepartureKey">
				<button class="button" @click="selectTrip()">Add to trip</button>
			</div>

			<div class="actions" v-if="!dateLoading && saved && selectedRoomCode && trip.tripItems[tripItemID]">

				<div class="not_booked_wrapper" v-if="status.code == 'not_booked'">
					<button class="button" @click="goToPayment()">Book Tour</button>
					<div class="remove-item" @click="removeFromTrip()">Remove from the trip</div>
				</div>

				<button class="button" @click="viewTrip()">View Trip</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.outer-wrapper {
		padding-bottom: 60px;
	}
	/* General */
	.heading {
		text-align: left;
		color: #118AB2;
	}
	.button {
		margin: 0 auto;
		display: block;
	}
	.heading-area {
		margin-bottom: 10px;
	}
	.heading-area .text {
		font-weight: 600;
		font-size: 16px;
		line-height: 17px;
		display: inline-block;
		vertical-align: middle;
		color: #000000;
	}
	.heading-area .icon {
		margin-right: 7px;
		vertical-align: middle;
	}
	.content {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;

		color: #000000;
	}

	/* Tour Header*/
	.tour-header {
		margin-bottom: 21px;
	}

	/* Summary section */
	.summary-section {
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
		margin-bottom: 20px;
		position: relative;
		padding-bottom: 10px;
	}
	.summary-section .selling-point {
		float: left;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #000000;
	}
	.summary-section .selling-point .point {
		background-image: url('../../../assets/tours/green-check.png');
		background-size: 12px;
		background-position: left;
		background-repeat: no-repeat;
		padding-left: 15px;
		margin-bottom: 5px;
	}
	.summary-section .price {
		float: right;
		font-weight: 400;
		font-size: 8px;
		line-height: 10px;
		text-align: right;
		color: #50555C;
	}
	.summary-section .price .starting {
		display: block;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		text-align: right;
		color: #50555C;
	}
	.summary-section .price .amount {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: right;

		color: #000000;
	}
	.summary-section  .price .amount .small {
		font-size: 8px;
	}

	/*  About */
	.about {
		position: relative;
		margin-bottom: 30px;
	}
	.about .icon {
		vertical-align: middle;
		margin-right: 5px;
	}
	.about .days {
		font-weight: 600;
		font-size: 16px;
		line-height: 17px;
		color: #118AB2;

		position: absolute;
		right: 0;
		top: 0;
	}
	.about .length {
		vertical-align: bottom;
	}
	.about .content .more {
		font-weight: 600;
		cursor: pointer;
	}
	
	/* What's Included section */
	.whats-included {
		margin-bottom: 20px;
	}
	.whats-included .section {
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	}
	.whats-included .section:last-child {
		box-shadow: none;
	}
	.whats-included .section .heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #393939;
		height: 35px;
		margin-bottom: 0;
	}
	.whats-included .section .heading h3 {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		width: 100%;
		padding-left: 10px;
	}
	.whats-included .section .heading .arrow {
		margin-right: 7px;
	}
	.whats-included .section .content {
		padding-bottom: 30px;
		padding-left: 25px;
		padding-top: 10px;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: #000000;
	}
	.whats-included .section:last-child .content {
		padding-bottom: 0;
	}
	.whats-included .section.meals .content {
		padding-left: 0;
	}
	.whats-included .section .content ul {
		margin: 0;
		padding-left: 17px;
	}
	.meals .heading-area {
		margin-bottom: 20px;
	}
	.meals .item {
		display: inline-block;
		text-align: center;
		margin: 0 20px 10px 10px;
		font-weight: 400;
		font-size: 11px;
		line-height: 12px;
		color: #000000;
		min-width: 45px;
	}
	.meals .item.none {
		opacity: 0.5;
	}
	.meals .item .icon {
		vertical-align: middle;
		margin-bottom: 7px;
	}
	.meals .item .amount {
		margin-bottom: 5px;
	}
	.meals .item .type {
		text-transform: capitalize;
	}
	/* End what's included section */

	/* Itinerary */
	.itinerary {
		margin-bottom: 30px;
	}
	.itinerary .heading-area {
		margin-bottom: 20px;
	}
	.itinerary .section {
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	}
	.itinerary .section .title {
		cursor: pointer;
		padding: 8px 0;
		position: relative;
	}
	.itinerary .section .title .icon {
		vertical-align: middle;
		margin-right: 8px;
		display: inline-block;
		width: 24px;
		text-align: center;
		line-height: 25px;
	}
	
	.itinerary .section .title .dot {
		width: 7px;
		height: 7px;
		display: inline-block;
		border-radius: 7px;
		margin-right: 8px;
		margin-left: 8px;
		background: #C4C4C4;
		
	}
	.itinerary .section .title.show .dot {
		background: #06D6A0;
	}
	.itinerary .section .title .text {
		font-weight: 700;
		font-size: 14px;
		line-height: 15px;

		color: #118AB2;
		display: inline-block;
		vertical-align: middle;
	}
	.itinerary .section .title .locations {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;

		color: #50555C;
		margin-left: 10px;
		padding-right: 30px;
	}
	.itinerary .section .title .arrow {
		transform: rotate(180deg);
		vertical-align: middle;
		position: absolute;
		right: 0px;
		top: 10px;
	}
	.itinerary .section .title.show .arrow {
		transform: rotate(0deg);
	}
	.itinerary .section .plaintext {
		padding-bottom: 10px;
	}
	.itinerary .section .content.hide {
		display: none;
	}
	.itinerary .section .plaintext p {
		font-size: 14px;
		font-weight: 400;
		line-height: 15px;
	}
	.itinerary .section .included {
		background: #F4F9FB;
		font-weight: 400;
		font-size: 11px;
		text-transform: capitalize;
		text-align: middle;
		padding: 7px 0;
		margin-bottom: 10px;
	}
	
	.itinerary .section .included .icon {
		vertical-align: middle;
		height: 15px;
		margin-right: 5px;
	}
	.itinerary .activity {
		background: #FFFFFF;
		border: 1px solid #F0F0F0;
		border-radius: 20px;
		padding: 21px 19px;
		display: flex;
		align-items: center;
		margin: 10px 15px 10px 15px;
	}
	.itinerary .activity.free_time {
		border: 1px solid rgba(6, 214, 160, 0.5);
	}
	.itinerary .activity.accommodation {
		background-color: #E9FAFF;
		border: none;
		padding: 21px 47px;
	}
	.itinerary .activity .icon {
		margin: 0 18px 0 3px;
	}
	.itinerary .activity .info {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
	}
	.itinerary .activity .info .name {
		font-weight: 600;
	}


	.itinerary .transport {
		margin-bottom: 10px;
	}
	.itinerary .transport .icon {
		display: inline-block;
		max-width: 30px;
		vertical-align: middle;
	}
	.itinerary .transport .text {
		display: inline-block;
		width: calc(100% - 30px);
		vertical-align: middle;
		margin-left: 10px;
	}
	.itinerary .transport .text .locations {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
	}
	.itinerary .transport .text .details {
		font-size: 11px;
		line-height: 13px;
		color: #50555C;
	}
	.itinerary .transport .text .details .title {
		color: #000000;
		font-weight: 700;
	}

	.itinerary .transport .text .details .type,
	.itinerary .transport .text .details .length {
		margin-right: 7px;
	}
	.itinerary .transport .text .details .length .icon,
	.itinerary .transport .text .details .distance .icon {
		margin-right: 4px;
	}

	.itinerary .transport .info {
		margin-top: 10px;
		margin-left: 10px;
		padding-left: 20px;
		border-left: 1px solid #C4C4C4;
		position: relative;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #000000;
		padding-bottom: 5px;
	}

	.itinerary .transport .info::before,
	.itinerary .transport .info::after {
		content: "";
		position: absolute;
		width: 7px;
		height: 7px;
		background: #C4C4C4;
		border-radius: 7px;
	}
	.itinerary .transport .info::before {
		left: -4.5px;
		top: 0;
	}
	.itinerary .transport .info::after {
		left: -4.5px;
		bottom: 0;
	}

	/* Pax area */
	.passengers {
		margin-bottom: 15px;
	}
	.pax-outer {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 5px;
	}
	.edit-section {
		width: 28px;
		float: right;
	}
	
	.map {
		margin-bottom: 30px;
	}
	.map .map-container {
		border-radius: 10px;
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		overflow: hidden;
		max-width: 480px;
	}
	.map .image {
		max-width: 100%;
	}

	.additional .item {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;

		color: #000000;

		margin-bottom: 17px;
	}
	.additional .item .detail_type {
		font-weight: 700;
	}
	.additional .item .more {
		font-weight: 600;
		cursor: pointer;
	}
	.additional .show-more {
		text-align: center;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;

		color: #000000;
		cursor: pointer;
		margin-bottom: 10px;
	}
	.additional .show-more span,
	.additional .show-more img {
		vertical-align: middle;
	}
	.dates {
		margin-bottom: 20px;
	}
	.dates .selector {
		max-width: 350px;
	}
	.dates .select-date {
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		text-align: center;

		color: #000000;
		margin-bottom: 10px;
	}
	.dates .heading {
		margin-top: 20px;
	}
	.dates .datepicker {
		margin: 0 auto;
		display: inline-block;
		width: 100%;
	}
	.dates .selected .date {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;

		color: #000000;
	}
	.dates .selected .date .edit-section {
		width: 20px;
	}
	.dates .selected .departure-info .option {
		display: flex;
	}
	.dates .departure-info {
		margin-bottom: 10px;
	}
	.dates .booked-ticket {
		color: black;
	}
	
	.dates .ticket .room_name {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: black;
	}
	.dates .ticket .availability {
		font-weight: 400;
		font-size: 10px;
		line-height: 24px;
	}
	.dates .ticket .button {
		margin: 0 auto;
		display: block;
		margin-top: 20px;
	}
	.dates .booked-ticket strong {
		font-weight: 600;
	}

	.dates .pricing {
		text-align: left;
		max-width: 350px;
		margin: 0 auto;
		vertical-align: middle;
		font-size: 14px;
	}
	.dates .pricing .pax-icon {
		margin-right: 5px;
	}
	.dates .pricing .pax-icon {
		width: 29px;
		vertical-align: middle;
	}
	.dates .pricing .price {
		float: right;
		padding-left: 5px;
		line-height: 25px;
	}
	.dates .pricing .price .standard.cross {
		font-size: 12px;
		text-decoration: line-through;
	}
	.dates .pricing .price .promo {
		padding-left: 5px;
	}
	.dates .pricing .total {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		margin-top: 10px;
	}
	.dates .pricing .total .total-value {
		float: right;
		font-size: 20px;
	}

	.actions {
		text-align: center;
	}
	.actions .summary {
		vertical-align: middle;
	}
	.actions .summary .pax-icon {
		width: 18px;
		vertical-align: middle;
	}
	.actions .summary .line {
		display: flex;
		justify-content: space-between;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: #000000;
		height: 40px;
	}
	.actions .summary .line .value {
		text-align: right;
	}

	.actions .summary .line.total {
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #000000;
	}
	.actions .summary .line.total .value {
		font-size: 20px;
	}

	.actions .not_booked_wrapper {
		margin: 5px 0;
	}
	.actions .not_booked_wrapper .button {
		margin-top: 30px;
	}
	.actions .message {
		margin: 0 auto;
		font-size: 12px;
		position: relative;
		padding-left: 46px;
		width: 253px;
		margin-bottom: 20px;
	}
	.actions .message img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 46px;
	}
	.remove-item {
		font-weight: 400;
		font-size: 12px;
		color: #50555C;

		background-image: url("../../../assets/trip/remove-icon.png");
		background-repeat: no-repeat;
		background-size: 16px 16px;
		background-position: right;

		display: inline-block;
		padding-right: 25px;
		line-height: 16px;
		margin: 0 auto;
		margin-bottom: 15px;
		margin-top: 15px;

		cursor: pointer;
	}
</style>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import Loader from '@/components/Loader'
	import TravellerIcon from '@/components/travellers/TravellerIcon.vue';
	import AddTravellerIcon from '@/components/travellers/AddTravellerIcon.vue';
	import ToursOption from "@/components/tours/ToursOption.vue";
	import TourHeader from "@/components/tours/TourHeader.vue";
	import ResultIcon from '@/components/ResultIcon.vue';
	import TripItemsSummary from '@/components/trips/TripItemsSummary.vue';
	import TourCard from "@/components/tours/TourCard.vue";
	import track from '@/helpers/track.js'

	export default {
		name: 'tourDetails',
		props: {
			saved: false, // Marks if the tour is saved or not (this view used for search results, or within a trip). Changes some data mapping.
			fullDetails: true, // Marks if it should display the full details for the tour, or just the basic info (tour card)
		},
		data() {
			return {
				// Saved Tour specific data
				tripID: this.$route.params.trip_id ? this.$route.params.trip_id : null,
				tripItemID: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,
				// End Saved Tour specific data

				tourID: this.$route.params.tour_id ? this.$route.params.tour_id : null,
				tour: null,
				tourDates: null,

				dateOptions: [],
				selectedDate: false, // Actual calendar date value
				selectedStartDate: false, // Formatted custom data value
				selectedEndDate: false, // Formatted custom data value
				selectedDepartureKey: false,
				selectedRoomCode: false,
				selectedPrice: false,
				fareKey: false,
				roomOptions: false,

				calendarSettings: {},

				tourLoading: false,
				error: false,
				dateLoading: false,

				// Content stuff
				showFullAbout: false,
				accommodationSummary: false,
				showAccommodation: false,
				showMeals: false,
				transportSummary: false,
				showTransport: false,
				activitySummary: false,
				showActivity: false,
				showIntro: false,
				itineraryDays: [],
				tourDetails: [],
				showFullAdditionalInfo: false,
		
			}
		},
		components: {
			Loader,
			TravellerIcon,
			AddTravellerIcon,
			ToursOption,
			TourHeader,
			ResultIcon,
			TripItemsSummary,
			TourCard
		},
		computed: {
			...mapState({
				basket: state => state.basket,
				account: state => state.account,
				alert: state => state.alert,
				trip: state => state.trip,
			}),
			...mapGetters({
				tripItemBookingValidity: "trip/tripItemBookingValidity",
				tripItemTravellersValidity: "trip/tripItemTravellersValidity",
			}),
			loading() {
				return this.trip.loading || this.tourLoading;
			},
			status() {
				if(this.saved) {
					return this.trip.tripItems[this.tripItemID].bookingStatus;
				}
				return false;
			},
			fromPrice() {
				if(this.tour && this.tour.advertised_price) {
					return this.tour.advertised_price.find(obj => { return obj.currency == 'GBP' });
				}
				return {
					amount: "00.00",
					currency: "GBP",
				}
			},
		},
		methods: {
			currencySymbol: (currencyCode) => helpers.currencySymbol(currencyCode),
			mealIcon: (meal) => helpers.mealIcon(meal),
			containsHTML: (str) => helpers.containsHTML(str),
			containsEmail: (str) => helpers.containsEmail(str),
			formatDate: (date) => helpers.formatDate(date),
			// formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
			getImage: (img) => helpers.getImage(img),
			...mapActions({
				initTrip: "trip/initTrip",
				updateTourDeparture: "trip/updateTourDeparture",
				removeFromBasket: "basket/removeFromBasket",
				setSelectedTour: "basket/setSelectedTour",
				getTour: "toursSearch/getTour",
				getTourDepartures: "toursSearch/getTourDepartures",
				getTourDeparture: "toursSearch/getTourDeparture",
				alertSuccess: "alert/success",
				alertError: "alert/error",
			}),
			manageTravellers() {
				router.push({ 
					name: "Manage Trip Item Travellers",
					params: {
						trip_id: this.tripID,
						trip_item_id: this.tripItemID,
					},
				});
			},
			editPax(traveller_id) {
				if(this.status.code == "not_booked") {
					router.push({
						name: "Edit Traveller",
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID,
							traveller_id: traveller_id,
						},
						query: {
							redirect: window.location.pathname
						}
					});
				}
			},
			setCalendarSettings() {
				let selectAttribute = {
					 highlight: {
						backgroundColor: 'white',
						borderColor: 'red',
						borderWidth: '3px',
						borderStyle: 'solid',
						width: '2.4rem',
						height: '2.4rem',
					},
					contentStyle: {
						color: 'grey',
					}
				}
				let attrs = this.dateOptions.map(t => ({
					key: t.key,
					highlight: {
						start: { fillMode: 'solid' },
						base: { fillMode: 'light' },
						end: { fillMode: 'light' },
					},
					customData: {
						status: 'available',
						endDate: t.dates.end ? t.dates.end : false,
						startDate: t.dates.start ? t.dates.start : false
					},
					dates: t.dates,
				}));

				// let availableDates = this.dateOptions.map(t => ({
				// 	start: t.dates.start, 
				// 	end: t.dates.start// Use start date for start & end - so that they can only select the first day
				// }));
				let availableDates = this.dateOptions.map(t => (this.formatDate(t.dates.start)));

				let settings = {
					attributes: attrs,
					availableDates: availableDates,
					// selectAttribute: selectAttribute, // CAN'T GET THIS TO WORKKKK
				};

				this.calendarSettings = settings;
			},
			selectDate(day) {
				// let selection = day.attributes[day.attributes.length -1];
				let attribute = day.attributes.find(el => this.formatDate(el.customData.startDate) == this.formatDate(day.date));
				if(!attribute) { // Not a valid date
					return false;
				}
				this.dateLoading = true;

				let key = attribute.key;
				
				this.selectedDate = attribute.customData.startDate;
				this.selectedStartDate = attribute.customData.startDate;
				this.selectedEndDate = attribute.customData.endDate;
				this.selectedDepartureKey = key;

				try {
					track.event("selectDepartureDate", {
						"itemID": this.tourID,
						"departureDate": new Date(this.selectedDate).toISOString(),
					})
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}

				this.getTourDeparture({ tourID: this.tourID, departureID: key })
					.then(response => {
						this.fareKey = response.data.data.fare_key;
						this.roomOptions = response.data.data.rooms;
						this.dateLoading = false;
					}, error => {
						this.alertError("There was a problem loading the availability for the selected date.");

						this.selectedDate = false;
						this.selectedStartDate = false;
						this.selectedEndDate = false;
						this.selectedDepartureKey = false;

						this.dateLoading = false;
					})
			},
			selectRoom(room) {
				console.log(this.selectedDate);
				try {
					track.event("selectRoom", {
						"itemID": this.tourID,
						"departureDate": new Date(this.selectedDate).toISOString(),
						"roomCode": room.code,
					})
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				this.selectedRoomCode = room.code;

				// If saved to a trip item, update in backend.
				if(this.saved) {
					this.dateLoading = true;

					this.updateTourDeparture({ tripID: this.tripID, tripItemID: this.tripItemID, tourID: this.tourID, departureID: this.selectedDepartureKey, roomCode: this.selectedRoomCode, fareKey: this.fareKey })
						.then(result => {
							this.alertSuccess("Departure updated successfully");
							this.dateLoading = false;
						},
						error => {
							this.alertError(error);
							this.selectedRoomCode = false;
							this.dateLoading = false;
						});
				}
			
			},
			selectTrip() {
				this.setSelectedTour({
					tourID: this.tourID,
					departureID: this.selectedDepartureKey,
					roomCode: this.selectedRoomCode,
					date: this.selectedDate,
					fareKey: this.fareKey,
				});

				router.replace({
					name: "Tours Select Trip",
					params: {
						tourID: this.tourID,
					}
				})
			},
			prepareItineraryDays() {
				this.tour.itinerary.data.days.forEach(element => {
					this.itineraryDays.push({...element, show: false});
				});
			},
			prepareTourDetails() {
				this.tour.details.forEach(element => {
					this.tourDetails.push({...element, show: false});
				});
			},
			prepareSummaryData() {
				let accommodationSummary = {};
				let transportSummary = {};
				let activitySummary = {};
				this.itineraryDays.forEach(day => {
					day.components.forEach(component => {

						if(component.type == "ACCOMMODATION") {
							let type = component.accommodation_dossier.property_type.label;
							if(!accommodationSummary[type]) {
								accommodationSummary[type] = [];
							}
							accommodationSummary[type].push(component.accommodation_dossier);
						}

						if(component.type == "TRANSPORT") {
							let type = component.transport_dossier.name;
							if(!transportSummary[type]) {
								transportSummary[type] = [];
							}
							transportSummary[type].push(component.transport_dossier);
						}
						
						if(component.type == "ACTIVITY") {
							let type = component.activity_dossier.name;
							if(!activitySummary[type]) {
								activitySummary[type] = [];
							}
							activitySummary[type].push(component.activity_dossier);
						}
					})
				});
				this.accommodationSummary = accommodationSummary;
				this.transportSummary = transportSummary;
				this.activitySummary = activitySummary;
			},
			getTransportIcon(name) {
				if(name == "Private Vehicle") { // Valid images
					return require('@/assets/tours/transport/private-vehicle.png');
				} else if (name == "Plane") {
					return require('@/assets/tours/transport/plane.png');
				}
				// Default image
				return require('@/assets/tours/transport/private-vehicle.png');
			},
			goToPayment() {
				if(this.status.code == 'not_booked') {
					// If validation not met, alert user.
					if(!this.tripItemBookingValidity(this.tripItemID).valid) { 
						this.alertError(this.tripItemBookingValidity(this.tripItemID).error);
					} else if(!this.tripItemTravellersValidity(this.tripItemID).valid) {  // redirect to travellers screen if there is pax management that needs to be done.
						// Go to the pax screen
						router.push({
							name: "Manage Trip Item Travellers",
							params: {
								trip_id: this.tripID,
								trip_item_id: this.tripItemID,
							},
							query: {
								successRedirect: "checkout",
							}
						});
					} else {
						// Otherwise Successful validation - go to book
						router.push({
							name: "Review",
							params: {
								trip_id: this.tripID,
								trip_item_id: this.tripItemID
							}
						})
					}
				}

			},
			removeFromTrip() {
				if(this.status.code == 'not_booked') {
					// Navigate to confirmation page
					router.push({
						name: 'Remove Trip Item',
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID
						}
					})
				}
			},
			viewTrip() {
				router.push({ name: 'Trip', params: { trip_id: this.tripID }})
			},
			alertAction(type) {
				if(type == 'traveller_validation') {
					// Go to the edit travellers page
					this.manageTravellers();
				} else if(type == 'dates') {
					// Scroll to fare options
					var element = this.$refs.dates;
					window.scrollTo({
						top: element.offsetTop,
						behavior: 'smooth'
					});
				}
			},
			async init() {
				if(this.saved) {
					this.$emit('update:topLayoutSettings', {
						left: {
							type: 'back',
							route: { name: 'Trip', params: { trip_id: this.tripID } },
						},
						right: {
							type: 'none',
						},
						overlayContent: true,
					});
				} else {
					this.$emit('update:topLayoutSettings', {
						left: {
							type: 'back',
							route: { name: 'Tours Results' },
						},
						right: {
							type: 'none',
						},
						overlayContent: true,
					});
					
				}
				
				
				if(!this.saved) { // Only track as a view result from the search process- not when saved to a trip
					try {
						track.event('viewResult', {
							itemType: 'tour',
							itemID: this.tourID,
						});
					} catch(e) {
						console.error(e);
						this.$rollbar.error("Tracking: " + e);
					}
				}

				this.tourLoading = true;
				
				// If not a saved tour, and there is a tour ID - fetch data directly from endpoint
				if(!this.saved && this.$route.params.tour_id) { 
					this.tourID = this.$route.params.tour_id ? this.$route.params.tour_id : null;
					
					try {
						let tourResponse = await this.getTour({ tourID: this.tourID });
						this.tour = tourResponse.data.data;

						let datesResponse = await this.getTourDepartures({ tourID: this.tourID });
						this.tourDates = datesResponse.data.data;
					} catch(error) {
						this.$rollbar.error(error);
						console.log(error);
						this.error = "Something went wrong."
					} finally {
						this.tourLoading = false;
					}
				} else if(this.saved) {
					// If the tour is saved to a trip - fetch data from trips module
					this.tripID = this.$route.params.trip_id ? this.$route.params.trip_id : null;
					this.tripItemID = this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null;
					
					await this.initTrip({ tripID: this.tripID, tripItemID: this.tripItemID }); // Init trip data if not already done

					if(this.trip.error) {
						this.error = this.trip.error;
					}
					this.tourID = this.trip.tripItems[this.tripItemID].data.tourID;
					this.tour = this.trip.tripItems[this.tripItemID].data.rawTourData;
					this.selectedDepartureKey = this.trip.tripItems[this.tripItemID].data.departureID;
					this.selectedRoomCode = this.trip.tripItems[this.tripItemID].data.roomCode;

					this.tourDates = this.trip.tripItems[this.tripItemID].data.dates;
				}

				this.prepareItineraryDays();
				this.prepareTourDetails();
				this.prepareSummaryData();

				// Manipulate the dates to work with the calendar
				try {
					// Configure calendar data
					let datesArray = [];
					for(let i in this.tourDates) {
						let data = {
							key: this.tourDates[i].id,
							dates: null, // Set below
						}
						if(this.tourDates[i].finish_date) { // If range, include as start/end obj
							data.dates = {
								start: new Date(this.tourDates[i].start_date),
								end: new Date(this.tourDates[i].finish_date)
							};
						} else { // Otherwise just single date
							data.dates = new Date(this.tourDates[i].start_date);
						}
						datesArray.push(data);
					}
					this.dateOptions = datesArray;

					if(datesArray.length > 0) {
						this.selectedDate = datesArray[0].dates.start; // Default to the first available date
					} else {
						this.selectedDate = false;
					}

					this.setCalendarSettings(); // Determine calendar settings from data

					if(this.selectedDepartureKey) {
						// Pre-select the saved selected date on the calendar
						if(!this.saved) {
							this.getTourDeparture({ tourID: this.tourID, departureID: this.selectedDepartureKey })
								.then(response => {
									this.fareKey = response.data.data.fare_key;
									this.roomOptions = response.data.data.rooms;

									this.selectedDate = response.data.data.start_date;
									this.selectedStartDate = response.data.data.start_date;
									this.selectedEndDate = response.data.data.finish_date;

									this.dateLoading = false;
								}, error => {
									this.selectedDate = false;
									this.selectedStartDate = false;
									this.selectedEndDate = false;
									this.selectedDepartureKey = false;

									this.dateLoading = false;
								})
						} else {
							// If saved, then we already have this in the trip state
							if(this.trip.tripItems[this.tripItemID].bookingStatus.code != "booked") {
								// Don't get room options if booked
								this.roomOptions = this.trip.tripItems[this.tripItemID].data.roomOptions;
							}

							this.selectedDate = this.trip.tripItems[this.tripItemID].data.startDate;
							this.selectedStartDate = this.trip.tripItems[this.tripItemID].data.startDate;
							this.selectedEndDate = this.trip.tripItems[this.tripItemID].data.finishDate;

						}
					}
				} catch(error) {
					this.$rollbar.error(error);
					console.error(error);
				}
				this.tourLoading = false;

				// Set the color of the arrow to white now that the tour header is showing.
				let updatedSettings = JSON.parse(JSON.stringify(this.$parent.topLayoutSettings));
				updatedSettings.left.color = 'white';
				this.$emit('update:topLayoutSettings', updatedSettings);
			}
		},
		mounted() {
			
		},
		async created() {
			await this.init();		
		}
	}
</script>

